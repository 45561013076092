import service from '@/utils/request'

// @Tags WarningData
// @Summary 创建WarningData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningData true "创建WarningData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devWarningData/createWarningData [post]
export const createWarningData = (data) => {
  return service({
    url: '/devWarningData/createWarningData',
    method: 'post',
    data
  })
}

// @Tags WarningData
// @Summary 删除WarningData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningData true "删除WarningData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devWarningData/deleteWarningData [delete]
export const deleteWarningData = (data) => {
  return service({
    url: '/devWarningData/deleteWarningData',
    method: 'delete',
    data
  })
}

// @Tags WarningData
// @Summary 删除WarningData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除WarningData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devWarningData/deleteWarningData [delete]
export const deleteWarningDataByIds = (data) => {
  return service({
    url: '/devWarningData/deleteWarningDataByIds',
    method: 'delete',
    data
  })
}

// @Tags WarningData
// @Summary 更新WarningData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningData true "更新WarningData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /devWarningData/updateWarningData [put]
export const updateWarningData = (data) => {
  return service({
    url: '/devWarningData/updateWarningData',
    method: 'put',
    data
  })
}

// @Tags WarningData
// @Summary 用id查询WarningData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.WarningData true "用id查询WarningData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /devWarningData/findWarningData [get]
export const findWarningData = (params) => {
  return service({
    url: '/devWarningData/findWarningData',
    method: 'get',
    params
  })
}

// @Tags WarningData
// @Summary 分页获取WarningData列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取WarningData列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devWarningData/getWarningDataList [get]
export const getWarningDataList = (params) => {
  return service({
    url: '/devWarningData/getWarningDataList',
    method: 'get',
    params
  })
}
